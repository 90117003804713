import React from "react";

const HelpAndSupportPage = () => {
  return (
    <div className="relative bg-gray-100 min-h-screen flex flex-col items-center py-10 mt-20 overflow-hidden">
      {/* Background SVG Blobs */}
      <div className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-[600px] h-[600px]">
        <svg
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full"
        >
          <path
            fill="#FBD7E3"
            d="M44.4,-65.5C55.4,-57.7,59.9,-41.5,65.6,-25.9C71.3,-10.3,78.1,5,76.5,19.6C75,34.2,65.2,47.9,53.1,58.1C41,68.3,26.6,74.8,12.3,73.8C-2,72.8,-16.4,64.4,-31.2,57.4C-46,50.5,-61.2,45.1,-66.4,36.3C-71.6,27.6,-66.8,15.5,-66.7,3.1C-66.7,-9.3,-71.4,-21.9,-66.5,-30.4C-61.6,-39,-47.1,-43.5,-34.3,-51.7C-21.5,-59.9,-10.8,-71.8,3.1,-76.4C17.1,-81,34.1,-78.1,44.4,-65.5Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>

      <div className="absolute bottom-0 right-0 transform translate-x-1/2 translate-y-1/2 w-[500px] h-[500px]">
        <svg
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full"
        >
          <path
            fill="#FBD7E3"
            d="M40.4,-64.2C54.4,-57.8,65.7,-45.8,71.7,-31.4C77.8,-17,78.6,-0.2,75.4,15.3C72.1,30.8,64.9,45,54.1,53.5C43.2,62.1,28.6,64.9,13.5,65.5C-1.7,66.1,-17.2,64.4,-33.3,58.6C-49.4,52.8,-66,42.9,-73.7,28.9C-81.3,14.9,-80.1,-3.2,-73.3,-17.7C-66.6,-32.2,-54.3,-42.9,-41.4,-49.8C-28.5,-56.8,-14.2,-59.9,0.8,-60.8C15.8,-61.8,31.5,-60.6,40.4,-64.2Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>

      {/* Help and Support Content */}
      <div className="max-w-5xl text-left bg-white shadow-xl rounded-2xl p-10 relative z-10 border border-gray-300">
        <h1 className="text-4xl font-bold text-pink-700 mb-8 text-center">Help and Support</h1>

        <div className="space-y-8 text-gray-700 leading-relaxed">
          {/* FAQs Section */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Frequently Asked Questions</h2>
            <div className="space-y-4">
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">How do I create an account?</h3>
                <p className="text-gray-600">
                  To create an account, click on the <span className="font-bold">"Sign Up"</span> button on the homepage and fill in the required details. You will receive a confirmation email to verify your account.
                </p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">How can I reset my password?</h3>
                <p className="text-gray-600">
                  If you forgot your password, click on the <span className="font-bold">"Forgot Password"</span> link on the login page and follow the instructions to reset it.
                </p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">How do I update my profile information?</h3>
                <p className="text-gray-600">
                  To update your profile information, log in to your account, go to the <span className="font-bold">"Profile"</span> section, and edit your details. Make sure to save the changes.
                </p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">How do I report technical issues or bugs?</h3>
                <p className="text-gray-600">
                  You can contact our support team through the <span className="font-bold">"Help"</span> section in the app or email us at <span className="font-bold">contact@smartmaheshwari.com</span> for assistance.
                </p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">What devices are supported by the app?</h3>
                <p className="text-gray-600">The app is currently only available for Android devices.</p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">How are matches prioritized?</h3>
                <p className="text-gray-600">
                  Matches are prioritized based on a combination of user preferences, mutual interests, and astrological compatibility.
                </p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">How do I delete or deactivate my account?</h3>
                <p className="text-gray-600">
                  You can delete or deactivate your account from the <span className="font-bold">settings</span> page. Once deactivated, your profile will no longer be visible to others.
                </p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">What is the purpose of this matrimonial app?</h3>
                <p className="text-gray-600">
                  This app is designed to help individuals in the Maheshwari community find suitable matches based on personal preferences, interests, and astrological compatibility using AI.
                </p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">Who can register on this app?</h3>
                <p className="text-gray-600">
                  This app is exclusively for members of the Maheshwari community looking for marriage prospects.
                </p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">How does the AI-based matchmaking system work?</h3>
                <p className="text-gray-600">
                  Our AI uses the data provided during registration, including preferences, interests, and astrological factors, to recommend highly compatible matches.
                </p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">Is the app free to use?</h3>
                <p className="text-gray-600">Yes, the app is free for all Maheshwari community members, with no hidden fees.</p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">How is my personal data used?</h3>
                <p className="text-gray-600">
                  Your personal information is used solely for matchmaking and is protected by strict privacy policies to ensure data security.
                </p>
              </div>
            </div>
          </section>

          {/* Contact Us Section */}
          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Contact Us</h2>
            <div className="space-y-6">
              <div>
                <h3 className="font-bold">Email Support</h3>
                <p>You can reach our support team at <span className="font-bold">support@miisco.com</span> for any assistance.</p>
              </div>
              <div>
                <h3 className="font-bold">Phone Support</h3>
                <p>
                  For immediate assistance, call us at <span className="font-bold">+1 234 567 890</span>. Our support team is available 24/7.
                </p>
              </div>
            </div>
          </section>

          {/* Additional Resources Section */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Additional Resources</h2>
            <div className="space-y-4">
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">User Guides</h3>
                <p className="text-gray-600">
                  Explore our comprehensive user guides to get the most out of our app. <a href="/guides" className="text-blue-500 hover:underline">View Guides</a>
                </p>
              </div>
              <div className="p-4 bg-gray-100 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-700">Community Forum</h3>
                <p className="text-gray-600">
                  Join our community forum to ask questions, share tips, and connect with other users. <a href="/forum" className="text-blue-500 hover:underline">Visit Forum</a>
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupportPage;
