import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div className="relative bg-gray-100 min-h-screen flex flex-col items-center py-10 mt-20 overflow-hidden">
      {/* Background SVG Blobs */}
      <div className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-[600px] h-[600px]">
        <svg
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full"
        >
          <path
            fill="#FBD7E3"
            d="M44.4,-65.5C55.4,-57.7,59.9,-41.5,65.6,-25.9C71.3,-10.3,78.1,5,76.5,19.6C75,34.2,65.2,47.9,53.1,58.1C41,68.3,26.6,74.8,12.3,73.8C-2,72.8,-16.4,64.4,-31.2,57.4C-46,50.5,-61.2,45.1,-66.4,36.3C-71.6,27.6,-66.8,15.5,-66.7,3.1C-66.7,-9.3,-71.4,-21.9,-66.5,-30.4C-61.6,-39,-47.1,-43.5,-34.3,-51.7C-21.5,-59.9,-10.8,-71.8,3.1,-76.4C17.1,-81,34.1,-78.1,44.4,-65.5Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>

      <div className="absolute bottom-0 right-0 transform translate-x-1/2 translate-y-1/2 w-[500px] h-[500px]">
        <svg
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full"
        >
          <path
            fill="#FBD7E3"
            d="M40.4,-64.2C54.4,-57.8,65.7,-45.8,71.7,-31.4C77.8,-17,78.6,-0.2,75.4,15.3C72.1,30.8,64.9,45,54.1,53.5C43.2,62.1,28.6,64.9,13.5,65.5C-1.7,66.1,-17.2,64.4,-33.3,58.6C-49.4,52.8,-66,42.9,-73.7,28.9C-81.3,14.9,-80.1,-3.2,-73.3,-17.7C-66.6,-32.2,-54.3,-42.9,-41.4,-49.8C-28.5,-56.8,-14.2,-59.9,0.8,-60.8C15.8,-61.8,31.5,-60.6,40.4,-64.2Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>

      {/* Privacy Policy Content */}
      <div className="max-w-5xl text-left bg-white shadow-xl rounded-2xl p-10 relative z-10 border border-gray-300">
        <h1 className="text-4xl font-bold text-pink-700 mb-8 text-center">Privacy Policy</h1>

        <div className="space-y-8 text-gray-700 leading-relaxed">
          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Introduction</h2>
            <p className="font-medium">
              This Privacy Policy describes how <span className="font-bold">Maheshwari Innovative IT Services LLP</span> ("we", "our", "us") collects, uses, shares, protects, or processes your information/personal data through our website and related services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Collection of Information</h2>
            <ul className="list-disc ml-6 space-y-2">
              <li><span className="font-bold">Personal Information:</span> Name, date of birth, address, telephone/mobile number, email ID, and identity/address proofs.</li>
              <li><span className="font-bold">Sensitive Data:</span> Payment instrument information or biometric information (with consent).</li>
              <li><span className="font-bold">Usage Data:</span> Behavioral, transactional, and preference data tracked during platform usage.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Usage of Information</h2>
            <ul className="list-disc ml-6 space-y-2">
              <li>Provide services requested by users.</li>
              <li>Enhance customer experience and resolve disputes.</li>
              <li>Conduct marketing research, fraud prevention, and legal compliance.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Sharing of Information</h2>
            <ul className="list-disc ml-6 space-y-2">
              <li>With group entities, corporate affiliates, and business partners.</li>
              <li>With third-party service providers for logistics, payment processing, and other services.</li>
              <li>With law enforcement agencies when required by law or to protect rights and safety.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Security Precautions</h2>
            <p>
              We adopt reasonable security measures to protect your data from unauthorized access or misuse. However, transmission over the internet cannot be completely secure, and users are responsible for protecting their account credentials.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Data Deletion and Retention</h2>
            <p>
              Users can delete their account via platform settings. We retain data as required by law or for legitimate purposes, even after account deletion, in anonymized form for research purposes.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Your Rights</h2>
            <p>
              You may access, rectify, update, or withdraw consent for your personal data. Withdrawal of consent may impact access to certain services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Consent</h2>
            <p>
              By using our platform, you consent to the collection, use, and sharing of your data as described in this Privacy Policy.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Changes to the Privacy Policy</h2>
            <p>
              We may update this Privacy Policy periodically. Users are advised to review it regularly for changes.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
