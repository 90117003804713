import React from "react";

const PricingPage = () => {
  return (
    <div className="relative bg-gray-100 min-h-screen flex flex-col items-center py-10 mt-20 overflow-hidden">
      {/* Background SVG Blobs */}
      <div className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-[600px] h-[600px]">
        <svg
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full"
        >
          <path
            fill="#FBD7E3"
            d="M44.4,-65.5C55.4,-57.7,59.9,-41.5,65.6,-25.9C71.3,-10.3,78.1,5,76.5,19.6C75,34.2,65.2,47.9,53.1,58.1C41,68.3,26.6,74.8,12.3,73.8C-2,72.8,-16.4,64.4,-31.2,57.4C-46,50.5,-61.2,45.1,-66.4,36.3C-71.6,27.6,-66.8,15.5,-66.7,3.1C-66.7,-9.3,-71.4,-21.9,-66.5,-30.4C-61.6,-39,-47.1,-43.5,-34.3,-51.7C-21.5,-59.9,-10.8,-71.8,3.1,-76.4C17.1,-81,34.1,-78.1,44.4,-65.5Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>

      <div className="absolute bottom-0 right-0 transform translate-x-1/2 translate-y-1/2 w-[500px] h-[500px]">
        <svg
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full"
        >
          <path
            fill="#FBD7E3"
            d="M40.4,-64.2C54.4,-57.8,65.7,-45.8,71.7,-31.4C77.8,-17,78.6,-0.2,75.4,15.3C72.1,30.8,64.9,45,54.1,53.5C43.2,62.1,28.6,64.9,13.5,65.5C-1.7,66.1,-17.2,64.4,-33.3,58.6C-49.4,52.8,-66,42.9,-73.7,28.9C-81.3,14.9,-80.1,-3.2,-73.3,-17.7C-66.6,-32.2,-54.3,-42.9,-41.4,-49.8C-28.5,-56.8,-14.2,-59.9,0.8,-60.8C15.8,-61.8,31.5,-60.6,40.4,-64.2Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>

      {/* Pricing Content */}
      <div className="max-w-7xl text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Find Your Perfect Match – Choose Your Plan
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          Whether you're exploring or committed to the journey, we have the perfect plan for you. Enjoy premium features to enhance your matchmaking experience!
        </p>

        <div className="flex flex-wrap justify-center gap-6 relative z-10">
          {/* Monthly Plan */}
          <div className="bg-white shadow-lg rounded-lg border border-gray-200 max-w-sm p-6 text-left">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              Monthly Plan
            </h2>
            <p className="text-lg font-bold text-pink-600 mb-4">₹99/month</p>
            <ul className="space-y-2 text-gray-700 mb-6">
              <li>✅ Full access to advanced matchmaking</li>
              <li>✅ View detailed compatibility scores and insights</li>
              <li>✅ Unlimited profile views and personalized recommendations</li>
              <li>✅ Priority support for a seamless experience</li>
            </ul>
            <a
              href="#"
              className="block text-center bg-pink-600 hover:bg-pink-700 text-white font-medium py-2 rounded-lg"
            >
              Get Started Now
            </a>
          </div>

          {/* Half-Yearly Plan */}
          <div className="bg-white shadow-lg rounded-lg border border-pink-600 max-w-sm p-6 text-left">
            <h2 className="text-2xl font-semibold text-pink-600 mb-2">
              Half-Yearly Plan (Most Popular)
            </h2>
            <p className="text-lg font-bold text-pink-600 mb-4">₹599 for 6 months</p>
            <p className="text-gray-500 mb-4">Save ₹95 compared to the monthly plan!</p>
            <ul className="space-y-2 text-gray-700 mb-6">
              <li>✅ All benefits of the Monthly Plan</li>
              <li>✅ Exclusive access to advanced astrology insights</li>
              <li>✅ Dedicated relationship guidance and tips</li>
              <li>✅ Profile boost for higher visibility</li>
              <li>✅ Complimentary compatibility report with every new connection</li>
            </ul>
            <a
              href="#"
              className="block text-center bg-pink-600 hover:bg-pink-700 text-white font-medium py-2 rounded-lg"
            >
              Choose Plan and Save Now
            </a>
          </div>
        </div>

        {/* Comparison Table */}
        <div className="mt-12 relative z-10">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">Compare Plans</h2>
          <table className="w-full max-w-4xl mx-auto table-auto border-collapse border border-gray-300">
            <thead>
              <tr className="bg-pink-600 text-white">
                <th className="border border-gray-300 p-2">Feature</th>
                <th className="border border-gray-300 p-2">Monthly Plan (₹99)</th>
                <th className="border border-gray-300 p-2">Half-Yearly Plan (₹599)</th>
              </tr>
            </thead>
            <tbody className="text-center">
              <tr>
                <td className="border border-gray-300 p-2">Advanced Matchmaking</td>
                <td className="border border-gray-300 p-2">✅</td>
                <td className="border border-gray-300 p-2">✅</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">Compatibility and Family Insights</td>
                <td className="border border-gray-300 p-2">✅</td>
                <td className="border border-gray-300 p-2">✅</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">Astrology Analysis</td>
                <td className="border border-gray-300 p-2">❌</td>
                <td className="border border-gray-300 p-2">✅</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">Profile Boost</td>
                <td className="border border-gray-300 p-2">❌</td>
                <td className="border border-gray-300 p-2">✅</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">Save Money</td>
                <td className="border border-gray-300 p-2">❌</td>
                <td className="border border-gray-300 p-2">✅ (₹95 savings)</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">Dedicated Support</td>
                <td className="border border-gray-300 p-2">✅</td>
                <td className="border border-gray-300 p-2">✅</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
